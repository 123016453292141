import {useCallback} from 'react'

type UseStyles = (s: Style) => ToStyleList
type ToStyleString = (s: Style, o: Record<string, boolean>) => string
type ToStyleList = (...args: (keyof Style | Record<string, boolean>)[]) => string
type Style = Record<string, string>

const flat: ToStyleString = (styles, obj) => Object
  .entries(obj)
  .filter(([, value]) => value)
  .map(([key]) => styles[key])
  .join(' ')

export const useStyles: UseStyles = (styles) => {
  const toStyleList: ToStyleList = (...args) => args
    .map((key) => typeof key === 'string' ? styles[key] || key : flat(styles, key))
    .join(' ')

  return useCallback(toStyleList, [styles])
}
