import captainSpyro from '../images/avatars/captain-spyro.png'
import colibry from '../images/avatars/colibry.png'
import cryptoGataro from '../images/avatars/crypto-gataro.png'
import gotzeuus from '../images/avatars/gotzeuus.png'
import sigz from '../images/avatars/sigz.png'
import potion from '../images/potion.png'
import css from './squad.module.scss'

import {Separator} from '../components/separator/separator'
import {SquadCard} from '../components/squad-card/squad-card'

const list = [{
  name: 'Captain Spyro',
  role: 'Founder & CTO',
  image: captainSpyro,
  reward: 100,
  twitter: 'https://twitter.com/captain_spyro',
}, {
  name: 'CryptoGataro',
  role: 'Blockchain Developer',
  image: cryptoGataro,
  reward: 100,
  twitter: '#',
}, {
  name: 'Gotzeuus',
  role: 'Investor & Advisor',
  image: gotzeuus,
  reward: 100,
  twitter: 'https://twitter.com/Gotzeuus',
}, {
  name: 'Colibry',
  role: 'Main Artist',
  image: colibry,
  reward: 80,
  twitter: '#',
}, {
  name: 'Sigz',
  role: 'Community Manager',
  image: sigz,
  reward: 60,
  twitter: 'https://twitter.com/Sigz_sol',
}]

const addDotToNumber = (number: number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')

export const Squad: React.FC = () => {
  return (
    <div className={css.squad}>
      <div className={css.title}>
        <div className={css.text}>
          Our <span className={css.red}>Squad</span>
        </div>
        <div className={css.line}></div>
        <img className={css.logo} src={potion} />
      </div>
      <div className={css.description}>
        <div className={css.title}>We be strong, solid an' daft</div>
        <Separator className={css.separator} />
        <p>
          Ahoy young gentleman o' fortune! Wanna know more about the original squad?
          Just check this here, we be all famous...
          Fer more information, just go on our so serious whitepaper.

          {
            /*
              Hello young pirate! Wanna know more about the team?
              Just check this, we are all famous there...
              For more information, just go on our so serious whitepaper.
            */
          }
        </p>
      </div>
      <div className={css.cards}>
        {
          list.map(({name, role, image, reward, twitter}, index) => <SquadCard
            nftImage={image}
            rank={index + 1}
            name={name}
            role={role}
            reward={addDotToNumber(reward * 500000)}
            twitter={twitter}
          />)
        }
      </div>
    </div>
  )
}
