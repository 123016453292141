import {HtmlHTMLAttributes} from 'react'
import {Separator} from '../separator/separator'
import css from './squad-card.module.scss'

export type SquadCardProps = HtmlHTMLAttributes<HTMLDivElement> & {
  nftImage: string
  rank: number
  name: string
  role: string
  reward: string
  twitter: string
}

export const SquadCard: React.FC<SquadCardProps> = ({nftImage, rank, name, role, reward, twitter}) => {
  return (
    <div className={css['squad-card']}>
      <div className={css.image}>
        <img className={css.nft} src={nftImage} />
        <div className={css.wanted}>wanted</div>
      </div>
      <div className={css.reward}>$YAAH {reward}</div>
      <Separator className={css.separator} />
      <div className={css.description}>
        <div className={css['rank-and-name']}>
          <div className={css.rank}>
            <span className={css.diez}>#</span>
            <span className={css.number}>{rank}</span>
          </div>
          <div className={css.name}><a title='Twitter' href={twitter}>{name}</a></div>
        </div>
        <div className={css.role}>{role}</div>
      </div>
    </div>
  )
}
