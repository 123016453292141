import {library} from '@fortawesome/fontawesome-svg-core'
import {faDiscord, faTwitter} from '@fortawesome/free-brands-svg-icons'
import {faBars, faBookDead, faExternalLink, faSkull, faXmark} from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import Div100vh from 'react-div-100vh'
import ReactDOM from 'react-dom/client'
import {QueryClient, QueryClientProvider} from 'react-query'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import {App} from './app'
import './index.scss'
import {Home} from './pages/home'
import {Roadmap} from './pages/roadmap'
import {Squad} from './pages/squad'

library.add(faTwitter, faDiscord, faBookDead, faSkull, faBars, faXmark, faExternalLink)

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <Div100vh>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<App />}>
              <Route path="*" element={<Home />} />
              <Route path="squad" element={<Squad />} />
              <Route path="mission" element={<Roadmap />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </React.StrictMode>
  </Div100vh>
)
