import {Outlet} from 'react-router-dom'
import css from './app.module.scss'
import {Footer} from './components/footer/footer'
import {Header} from './components/header/header'

const App: React.FC = () => <div className={css.app}>
  <Header />
  <Outlet />
  <Footer />
</div>

export {App}

