import {HtmlHTMLAttributes} from 'react'

import map from '../images/home-button.svg'
import css from './home.module.scss'

const BigButton: React.FC<HtmlHTMLAttributes<HTMLDivElement>> = (props) =>
  <div {...props}>
    <a href="https://pirategames.win/">
      <div className={css.roadmap}>
        <img src={map} />
      </div>
    </a>
  </div>

export const Home: React.FC = () => {
  return (
    <div className={css.home}>
      <div className={css.mobile}>
        <div className={css.title}>
          Pirate <span>Squad</span>
        </div>
        <BigButton />
      </div>
      <div className={css.image}></div>
      <BigButton className={css.desktop} />
    </div>
  )
}
