import {HtmlHTMLAttributes} from 'react'
import css from './separator.module.scss'

export type SeparatorProps = HtmlHTMLAttributes<HTMLDivElement>

export const Separator: React.FC<SeparatorProps> = ({className, ...props}) => {
  return (
    <div className={`${css.separator} ${className ?? ''}`} {...props}>
      <div className={css.line}></div>
      <div className={css.dots}>
        <div className={css.dot}></div>
        <div className={css.dot}></div>
        <div className={css.dot}></div>
      </div>
      <div className={css.line}></div>
    </div>
  )
}
