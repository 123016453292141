import css from './footer.module.scss'

export const Footer: React.FC = () => {
  return (
    <div className={css.footer}>
      <div className={css.copyright}>
        © 2023, Pirate Squad<br />
        Ahoy! Don't plunder a gentleman o' fortune
      </div>
    </div>
  )
}
