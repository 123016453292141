import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useState} from 'react'
import {Link, LinkProps, useMatch, useResolvedPath} from 'react-router-dom'
import logo from '../../images/logo.png'
import smallButtonImage from '../../images/small-button.svg'
import {useStyles} from '../../utils/css'
import css from './header.module.scss'

const CustomLink = ({children, to, ...props}: LinkProps) => {
  const resolved = useResolvedPath(to)
  const match = useMatch({path: resolved.pathname, end: true})

  return (
    <Link
      className={match ? css.active : ''}
      to={to}
      {...props}
    >
      {children}
    </Link>
  )
}

export const Header: React.FC = () => {
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false)

  const styles = useStyles(css)

  const mobileMenuCss = styles({
    menu: true,
    open: mobileMenuOpened,
  })

  if (mobileMenuOpened) {
    document.body.classList.add('overflow-hidden')
  } else {
    document.body.classList.remove('overflow-hidden')
  }

  const closeMobileMenu = () => {
    setMobileMenuOpened(false)
  }

  return (
    <div className={css.header}>
      <div className={css.bg}>
        <div className={css['lane-1']}>
          <img src={smallButtonImage} />
          <img src={smallButtonImage} />
        </div>
        <div className={css['lane-2']}></div>
        <div className={css['lane-3']}></div>
      </div>
      <Link to="/">
        <img className={css.logo} src={logo} />
      </Link>
      <button className={css['mobile-menu-button']} onClick={() => setMobileMenuOpened(true)}>
        <FontAwesomeIcon icon={['fas', 'bars']} />
      </button>
      <nav className={mobileMenuCss}>
        {mobileMenuOpened &&
          <Link to="/" onClick={closeMobileMenu}>
            <img className={`${css.logo} ${css.mobile}`} src={logo} />
          </Link>
        }
        <div className={css.links}>
          <CustomLink to="/squad" onClick={closeMobileMenu}>Squad</CustomLink>
          <CustomLink to="/mission" onClick={closeMobileMenu}>Mission</CustomLink>
          <a title='Hub'
            target="_blank"
            rel="noopener noreferrer"
            href="https://whitepaper.piratesquadnft.com/"
          >Whitepaper</a>
          <a title='Hub'
            target="_blank"
            rel="noopener noreferrer"
            href="https://pirategames.win/"
          >Play Now!</a>
          <div className={css.social}>
            <a
              title='Twitter'
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/PirateSquadNFT?s=20&t=lsWnaqSQc4n7zgZFawENnw"
            >
              <FontAwesomeIcon icon={['fab', 'twitter']} />
            </a>
            <a
              title='Discord'
              target="_blank"
              rel="noopener noreferrer"
              href="https://discord.gg/piratesquadnft"
            >
              <FontAwesomeIcon icon={['fab', 'discord']} />
            </a>
          </div>
        </div>
        <button className={css['close-mobile-menu']} onClick={closeMobileMenu}>
          Close
        </button>
      </nav>
    </div>
  )
}
