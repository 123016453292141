import boat from '../images/boat-light.jpeg'
import map from '../images/map-icon.png'
import css from './roadmap.module.scss'

export const Roadmap: React.FC = () => {
  return (
    <div className={css.roadmap}>
      <div className={css.title}>
        <div className={css.text}>
          Our <span className={css.red}>Mission</span>
        </div>
        <div className={css.line}></div>
        <img className={css.logo} src={map} />
      </div>
      <div className={css.intro}>
        <div className={css.text}>
          <p className={css.ahoy}>
            Ahoy!
          </p>
          <p>
            We are recruiting new members! Let's sail the Web3 seas together!
          </p>
          <p>
            Pirate Squad is a pirate-themed Game hub where players can compete against each other in various mini-games and wager to win tokens and NFTs. Do you trust your skills? Then bet big… if you dare…
          </p>
          <p>
            Holding a Pirate Squad NFT will gives you many advantages such as the ability to wager real tokens/NFTs, access to exclusive content and levels, increased rewards, reduced fees, and the ever exclusive Rev Share.
          </p>
          <p>
            With wonderful visuals from an experienced studio developing exclusive mini-games, its a fun and exciting way to earn rewards and experience the world of GameFi!
          </p>
        </div>
        <div className={css.image}>
          <img src={boat} alt="Boat" />
        </div>
      </div>
      {/* <Separator className={css.separator} />*/}
    </div>
  )
}
